import React from 'react'
import { Link } from 'gatsby'
import LayoutBs from '../../components/LayoutBs'
import Header from '../../components/Header/header'
import Footer from '../../components/Footer/footer'
import "animate.css/animate.min.css";
import {consulting} from '../../components/strings'
import akern from "../../images/partners/akern.png"
import coche_box from "../../images/partners/coche_box.png"
import sarayresidence from "../../images/partners/sarayresidence.png"
import photoschweda from "../../images/partners/photoschweda.png"
import swiss from "../../images/partners/swiss_jewellery.png"
import modroizeleno from "../../images/partners/modroizeleno.png"
import kupitehniku from "../../images/partners/kupitehniku.png"
import labconsulting from "../../images/partners/labconsulting.png"
import bauwach from "../../images/partners/bauwach.png"
import zeljo from "../../images/partners/zeljo.png"
import AOS from 'aos';
import Helmet from 'react-helmet'

 class ConsultingPageBs extends React.Component {
    componentDidMount(){
        AOS.init({
          duration : 500
        })
      }
  render() {

    return (
      <LayoutBs>
           <Helmet
          title="WELL –Konzalting | Agencija za digitalni marketing"
          meta={[
            { name: 'description', content: 'WELL-usluge se protežu od najzahtjevnijih web dizajna, preko SEO optimizacije pa sve do modernih metoda oglašavanje za Google i društvene medije.' },
            {name: 'og:image', content: "https://www.wellpromotion.ba/images/well_prmotion_marketing_agency.jpg" },
            {name: 'og:image:type', content: "image/jpg" },
            {name: 'og:type', content: "website" },
            {name: 'og:url', content: "https://wellpromotion.ba"},
            {name: 'twitter:card', content: "https://www.wellpromotion.ba/images/well_prmotion_marketing_agency.jpg"},
            {name: 'twitter:image:alt', content: "Well Business Promotion Marketing Agency" },
            ]} ></Helmet>
          <div id="consulting">
          <Header prefix_string_page="bs"/>
          <div data-aos='fade-zoom-in'>
          
            <section id="p_consulting" className="section pages">
            <div className="overlay_image"></div>
            <div className="overlay_color"></div>
            <div className="container">        
       
                <h1 className="title">{consulting}</h1>

                <div className="content">
    <p><strong>
    Veoma je mali broj komapnija koje su uspjele razviti uspješan biznis bez stručnog savjeta. 
    U tu svrhu se angažuju konzultanti koji objektivno analiziraju Vašu situaciju i na osnovu 
    rezultata analize Vas savjetuju po pitanju trenutnog i budućeg poslovanja.
    </strong></p>
    <p>Nerijetko se susrećemo sa firmama nezadovoljnim učinkom vlastitih marketinških kampanja. 
    Investirali su “brdo” novca a nisu vidjeli efekat. 
Već nakon kraće analize poslovanja ovih firmi i pregleda dotadašnjih marketinških kampanja se u većini 
slučajeva odmah ustanovi da nisu ispunjene osnovne pretpostavke za obavljanje marketinških kampanja poput:</p>
<ul>
    <li>Definisanje jasnog korporativnog identiteta,</li>
    <li>Analiza tržišta, konkurencije i ciljne grupe, </li>
    <li>Definisanje beneficija koje Vaši proizvodi ili usluge pružaju potencijalnim kupcima, </li>
    <li>Definisanje Vaših prednosti u odnosu na konkurenciju,</li>
    <li>Usklađivanje finansijskih mogućnosti sa obimom marketinških kampanja itd.</li>
</ul>
<p>Iste pretpostavke važe i za digitalni marketing. Bez prethodne temeljite analize poslovanja klijenta praktično je nemoguće odraditi kvalitetan digitalni marketing. 
Dobru web stranicu, online trgovinu, kvalitetne digitalno-marketinške kampanje (Google AdWords, Facebook, Instagram, LinkedIn, Xing …) moramo unaprijed osmisliti prema
 osobenostima Vašeg poslovanja i prilagoditi ih Vašim ciljevima i objektivnim mogućnostima. 
Sve su to stvari koje se prethodno definišu kroz konzalting. </p>

<h3>Kada angažirati konzultanta?</h3>
<p>Konzultante možete angažovati u bilo kojoj fazi Vašeg poslovanja, ali je sigurno najbolji momenat za 
    konzalting početna faza rada firme ili početak rada na nekom projektu poput plasiranja novih proizvoda ili usluga na tržište.</p>

<p><strong>Uz konzalting se u pravilu znatno brže i sigurnije napreduje u poslovanju.</strong></p>
<p>Putem konzaltinga ćete mnogo lakše moći sagledati okolnosti sa kojima ćete se suočavati u toku poslovanja, sopstvene 
    mogućnosti u odnosu na konkurenciju kao i interes Vaše ciljne grupe za projekat. Procjena resursa koje trebate 
    investirati da biste postigli cilj kao i razmatranje zakonskih regulativa koje mogu kratkoročno i dugoročno uticati
     na poslovanje su također stavke koje ćete mnogo lakše sagledati uz dobar konzalting. 
Konzultanti mogu objektivnijie prepoznati prednosti i nedostatke Vašeg pristupa tržištu.</p>
<p><strong>Ne kaže se bez razloga da su informacija i savjeti stručnjaka blaga vrijedni. </strong></p>

<p><strong>Dogovorite prvo besplatno savjetovanje putem kontaktnog formulara ili telefonskog poziva. Odgovaramo u roku od 24 sata radnim danima.</strong></p>
<Link to="/bs/kontakt" className="button gold is-icon-back is-arrow-right">Ugovori termin<span class="icon arrow"></span></Link>
<a href="tel:+38733831428" className="line">+387 33 831 428</a>

<br /><br /><br />
<h3>NAŠI KLIJENTI (KONZALTING / BRANDING)</h3>

<div className="columns is-multiline consulting-clients">
    <div className="column is-2">
        <a href="https://akern.at/" target="_blank"><img src={akern} alt="Partner 1" /></a>
    </div>
    <div className="column is-2">
        <a href="http://cochebox.com/" target="_blank"><img src={coche_box} alt="Partner 1" /></a>
    </div>
    <div className="column is-2">
        <a href="http://sarayresidence.ba/" target="_blank"><img src={sarayresidence} alt="Partner 1" /></a>
    </div>
    <div className="column is-2">
        <a href="http://photoschweda.at/" target="_blank"><img src={photoschweda} alt="Partner 1" /></a>
    </div>
    <div className="column is-2">
        <a href="http://swissjewellery.ba" target="_blank"><img src={swiss} alt="Partner 1" /></a>
    </div>
    <div className="column is-2">
        <a href="https://modroizeleno.com/" target="_blank"><img src={modroizeleno} alt="Partner 1" /></a>
    </div>
    <div className="column is-2">
        <a href="https://kupitehniku.ba/" target="_blank"><img src={kupitehniku} alt="Partner 1" /></a>
    </div>
    <div className="column is-2">
        <a href="https://www.labconsulting.at/" target="_blank"><img src={labconsulting} alt="Partner 1" /></a>
    </div>
    <div className="column is-2">
        <a href="http://bauwach.com/" target="_blank"><img src={bauwach} alt="Partner 1" /></a>
    </div>
    <div className="column is-2">
        <a href="https://zeljo.at" target="_blank"><img src={zeljo} alt="Partner 1" /></a>
    </div>
</div>
                </div>
                </div>
             

                 
</section>
        </div>
        

        </div>

          <Footer/>
      </LayoutBs>
    )
  }
}


export default ConsultingPageBs